import React from 'react'
import App from 'App'
import styled from 'styled-components'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { SITE_LINK_PUBLISHING, SITE_LINK_ANALYTICS, CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import Footer from 'components/Footer'
import { Flex, Box, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H3 } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import imageHeader from 'static/images/integrations/instagram/header_picture.webp'
import imageToolManage from 'static/images/integrations/instagram/tools_to_manage.webp'
import imageText1 from 'static/images/integrations/instagram/image_text_1.webp'
import imageText2 from 'static/images/integrations/instagram/image_text_2.webp'
import imageText3 from 'static/images/integrations/instagram/image_text_3.webp'
import imageText4 from 'static/images/integrations/instagram/image_text_4.webp'
import imageText5 from 'static/images/integrations/instagram/image_text_5.webp'
import imageText6 from 'static/images/integrations/instagram/image_text_6.webp'
import imageText7 from 'static/images/integrations/instagram/image_text_7.webp'
import imageText8 from 'static/images/integrations/instagram/image_text_8.webp'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import PageHeader from './components/PageHeader'
import ToolManageSection from './components/ToolManageSection'
import TextImageSection from './components/TextImageSection'
import FaqSection from './components/FaqSection'
import GetStartedSection from '../GetStartedSection'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const NETWORK = 'Instagram'

const TEXT_IMAGES_SECOND = [
  {
    header: {
      title: `Simplify ${NETWORK} Content Development & Profile Posting`,
      matches: [NETWORK],
    },
    textFirst: false,
    image: imageText1,
    items: [
      {
        title: 'Easily Plan, Publish and Boost Posts',
        description: `Draft posts for one or many ${NETWORK} pages — upload videos, attach images from a media library, apply targeting, tag users and locations. Schedule image, video and carousel posts with direct publishing.`,
      },
      {
        title: 'Manage the Content Calendar',
        description: `Easily review and manage ${NETWORK} content from a central publishing calendar — quickly filter by tag, profile and date.`,
      },
      {
        title: 'Seamlessly With Approval Workflow',
        description:
          'Vista’s built-in approval workflow is perfect for legal, compliance, agency collaboration or peer review.',
      },
    ],
    linkText: 'MORE ON PUBLISHING',
    link: SITE_LINK_PUBLISHING,
  },
  {
    header: {
      title: `Scheduling For All ${NETWORK} Post Types`,
      matches: ['Post Types'],
    },
    textFirst: true,
    image: imageText4,
    headerMaxWidth: '100%',
    items: [
      {
        title: 'Reels',
        description: `Visually plan & schedule Reels to automatically publish. Moderate & reply to comments using the Inbox tool, and get detailed Reels Analytics to optimize all of your posts.`,
      },
      {
        title: 'Carousels',
        description: `Schedule ${NETWORK} carousel posts (multiple photos and/or videos in one post) to publish automatically with Vista Social. In just a few clicks, you’ll have engagement-worthy photo dumps scheduled to publish at your disposal.`,
      },
      {
        title: 'Images',
        description:
          'Looking to simply publish on-off images to your feed? Easily upload images, design with Canva or find great UGC content to automatically publish.',
      },
      {
        title: 'Videos',
        description: `Plan & Schedule ${NETWORK} videos to your feed to automatically publish. Moderate and reply to comments, get detailed video analytics to optimize your posts.`,
      },
      {
        title: 'Stories',
        description: `Create stunning ${NETWORK} Stories in Vista Social, then schedule them at your best time to post. Your stories are sent to your phone when it's time to publish.`,
      },
    ],
  },
  {
    header: {
      title: `Publish Reels, Videos & Stories With Trending Audio`,
      matches: ['Trending Audio'],
    },
    headerMaxWidth: '100%',
    textFirst: false,
    image: imageText8,
    items: [
      {
        title: 'Pick the best trending audio',
        description: `Choose your audio from a list of thoughtfully curated and maintained trending sounds.`,
      },
      {
        title: 'Upload your own sounds',
        description: `Easily upload sounds from ${NETWORK} or your own videos or sound files.`,
      },
      {
        title: 'Add audio options',
        description: 'Fully replace the audio in your video or add it as a background sound.',
      },
    ],
  },
]

const TEXT_IMAGES_THIRD = [
  {
    header: {
      title: 'Hashtag Tools To Help You Reach New Followers',
      matches: ['Hashtag Tools'],
    },
    textFirst: false,
    image: imageText5,
    items: [
      {
        title: 'Hashtag suggestions',
        description: 'Vista Social will recommend new hashtags based on the once you are using already!',
      },
      {
        title: 'Save captions',
        description: `Looking to re-use hashtags and captions? Save your favorite hashtags and captions for easy re-use.`,
      },
      {
        title: 'First comments',
        description:
          'Kick start your engagement by posting up to 5 additional comments for image, video, carousel or reel you publish!',
      },
    ],
  },
]

const TEXT_IMAGES_FOURTH = [
  {
    header: {
      title: `Drive Traffic & Sales From ${NETWORK}`,
      matches: ['Traffic & Sales'],
    },
    textFirst: true,
    image: imageText6,
    items: [
      {
        title: `Tag products from ${NETWORK} Shops`,
        description: `Give followers more ways to shop: Tag products from your ${NETWORK} Shop directly to your image, video, & carousel posts.`,
      },
      {
        title: 'Mention and tag users',
        description: `Mention users in your captions and tag them in your images.`,
      },
      {
        title: 'Tag locations',
        description: 'Tag location locations to drive more local interest to your posts.',
      },
    ],
  },
  {
    header: {
      title: 'Efficiently Manage Followers & Messages as a Team',
      matches: [],
    },
    textFirst: true,
    image: imageText2,
    items: [
      {
        title: 'Manage the Social Inbox as a Team',
        description:
          'Prevent duplication of efforts with team tools — mark as complete, view audit trails and track real-time progress.',
      },
      {
        title: 'Stay Productive With Intuitive Workflows',
        description: `Streamline ${NETWORK} Page management with message tagging, task routing and real-time status updates for team wide visibility.`,
      },
      {
        title: 'Easily Customize Page Access & Governance',
        description:
          'Organize teams and Pages to fit your needs — great for agencies, multi-location or global businesses.',
      },
    ],
  },
  {
    header: {
      title: `Measure performance with ${NETWORK} reports`,
      matches: [NETWORK],
    },
    textFirst: false,
    image: imageText3,
    items: [
      {
        title: 'Get post-level performance insight',
        description: `Easily identify top performing posts with Vista Social's analytics. Track comments, likes and engagement.`,
      },
      {
        title: 'Report across one or more profiles',
        description: `Compare follower growth, engagement metrics, hashtag trends and more across one or more ${NETWORK} profiles.`,
      },
      {
        title: 'Create presentation-ready reports',
        description:
          'Evaluate and compare performance by downloading PDF reports using built-in or fully customized templates.',
      },
    ],
    linkText: 'MORE ON ANALYTICS',
    link: SITE_LINK_ANALYTICS,
  },
]

const IntegrationsSocial = () => {
  return (
    <App fullHeader>
      <SEO
        title={`${NETWORK} management tools for business`}
        description={`Dynamically engage your audience with a powerful set of tools to manage your ${NETWORK} Pages. Directly publish reels, images, videos, and carousels. Efficiently manage conversations. Measure performance with customizable reports.`}
        path="/integrations/instagram/"
      />

      <PageHeader
        network={NETWORK}
        headerText={`${NETWORK} management tools for business`}
        description={`Dynamically engage your audience with a powerful set of tools to manage your ${NETWORK} Pages. Directly publish reels, images, videos, and carousels. Efficiently manage conversations. Measure performance with customizable reports.`}
        image={imageHeader}
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex my="xl" flexDirection="column" alignItems="center" width="100%">
            <Flex zIndex="1" alignItems="center" flexDirection="column" mx="auto">
              <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
                Plan Your {NETWORK} Reels, Stories and Feed posts With The
                <br />{' '}
                <Text as="span" color="primary" fontSize="3xl" fontWeight="bold">
                  Visual {NETWORK} Planner
                </Text>{' '}
              </H3>
            </Flex>
            <Flex
              mt="xl"
              justifyContent="center"
              flexDirection={{ mobile: 'column', desktop: 'row' }}
              alignItems="center"
              width="100%"
            >
              <Flex
                minWidth={{ mobile: '100%', tablet: '445px' }}
                width="100%"
                alignItems="center"
                mx={{ mobile: 'auto', tablet: 0 }}
                flexDirection="column"
                order={{ mobile: 1, desktop: 2 }}
              >
                <Flex width="100%" justifyContent="center" alignItems="space-between" flexDirection="column">
                  <Grid gridGap={pxToRem(24)} gridTemplateColumns="repeat(1, 1fr)">
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text olor={COLOR_CONSTANTS.DENIM} fontSize="xl" ml="s" textAlign="left">
                        Arrange your upcoming scheduled posts with a drag-and-drop to find the perfect look for your
                        feed.
                      </Text>
                    </Flex>
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text color={COLOR_CONSTANTS.DENIM} fontSize="xl" ml="s" textAlign="left">
                        Easily add, remove or change scheduled reels, feed posts and stories right from the planner.
                      </Text>
                    </Flex>
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text color={COLOR_CONSTANTS.DENIM} fontSize="xl" ml="s" textAlign="left">
                        Source images and videos from Canva, stock photos and user-generated content.
                      </Text>
                    </Flex>
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text color={COLOR_CONSTANTS.DENIM} fontSize="xl" ml="s" textAlign="left">
                        Customize thumbnails and apply trending sounds to your videos.
                      </Text>
                    </Flex>
                    <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                      <Box
                        mt={{ mobile: 0, tablet: '11px' }}
                        minWidth="8px"
                        width="8px"
                        height="8px"
                        borderRadius={radius.pill}
                        bg={colors.primary}
                      />
                      <Text color={COLOR_CONSTANTS.DENIM} fontSize="xl" ml="s" textAlign="left">
                        Easily format images with built-in image editor.
                      </Text>
                    </Flex>
                  </Grid>
                </Flex>
              </Flex>
              <Flex
                maxWidth="594px"
                minWidth={{ mobile: '100%', desktop: '516px', desktopWide: '594px' }}
                ml={{ mobile: 'auto', desktop: 0 }}
                mr={{ mobile: 'auto', desktop: 'l' }}
                mt={{ mobile: 'l', desktop: 0 }}
                order={{ mobile: 2, desktop: 1 }}
              >
                <LocalImage
                  src={imageText7}
                  width="100%"
                  maxWidth="594px"
                  mx="auto"
                  alt={`Plan Your ${NETWORK} Posts By Dragging And Dropping With The Visual ${NETWORK} Planner For Reels, Stories, & More`}
                />
              </Flex>
            </Flex>
          </Flex>

          <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
            <G2FeaturesComponent />
          </Flex>
        </Container>
      </Wrapper>

      <ToolManageSection
        network={NETWORK}
        headerText={`Tools to Manage Multiple ${NETWORK} Profiles`}
        description={`Vista Social’s ${NETWORK} integration includes publishing, boosting, response management and reporting tools for ${NETWORK} Business Profiles. Plan content, engage your audience, collaborate as a team and measure efforts from a single platform.`}
        image={imageToolManage}
        tools={[
          {
            title: `Create Engaging ${NETWORK} Content`,
            description: `Plan, create and publish engaging posts with a centralized content calendar, intuitive workflows and creative tools for ${NETWORK} publishing.`,
          },
          {
            title: 'Manage Conversations & Build Relationships',
            description:
              'Never miss a comment, feed post, share or a message with a unified inbox and powerful social tools for engagement.',
          },
          {
            title: `Benchmark Your ${NETWORK} Performance`,
            description: `Gather paid and organic insights for one or many ${NETWORK} Profiles to measure fan growth, engagement and post performance.`,
          },
        ]}
      />

      <TextImageSection textImages={TEXT_IMAGES_SECOND} />

      <TextImageSection textImages={TEXT_IMAGES_THIRD} background={COLOR_CONSTANTS.SALT} />

      <TextImageSection textImages={TEXT_IMAGES_FOURTH} />

      <FaqSection type="instagram" />

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default IntegrationsSocial
